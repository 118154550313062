<template>
  <div class="row  px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Monthly Tips
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card  p-60px">
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>
          <div v-if="loading == false">
            <div class="row" v-for="(tip, index) in tips.tips" :key="index">
              <div class="col-12 col-sm-12 pl-0">
                <h2 class="tip-title">{{ tip.month }}</h2>
              </div>
              <div class="col-12 col-sm-12 col-md-6 pl-0">
                <textarea
                  name="tip"
                  cols="30"
                  rows="4"
                  class="form-control input-tip"
                  :placeholder="`Type ${tip.month} monthly tip...`"
                  v-model="tip.tip"
                ></textarea>
              </div>
              <div
                class="col-12 col-sm-12 col-md-6 mt-4 mt-md-0 pr-0 pl-0 pl-md-3"
              >
                <h6 class="font-weight-bold sub-title-tips">
                  Default Montly Tip
                </h6>
                <p v-html="tip.default" class="text-tips"></p>
              </div>
              <div class="col-12 col-sm-12 bg-gray p-3 mt-3 mb-4 border-all">
                <input
                  type="radio"
                  :name="'publish-tip-' + index"
                  class="mr-2"
                  :value="true"
                  v-model="tips.use_defaults"
                />
                <label for="" class="color-gray mb-0">Use Custom Tip</label>
                <input
                  type="radio"
                  :name="'publish-tip-' + index"
                  class="ml-4 mr-2"
                  :value="false"
                  v-model="tips.use_defaults"
                />
                <label for="" class="color-gray mb-0">Use Default Tip</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <button class="button-expertel float-right" @click="saveTips()">
                  Submit
                </button>
                <button
                  class="btn btn-outline-expertel float-right mr-3 rounded-0"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      tips: [],
      loading: true
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/monthly_tips`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.tips = response.data;
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    saveTips() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/monthly_tips`,
        responseType: "json",
        data: {
          management: "custom",
          months: {
            january: this.tips.tips[0].tip,
            february: this.tips.tips[1].tip,
            march: this.tips.tips[2].tip,
            april: this.tips.tips[3].tip,
            may: this.tips.tips[4].tip,
            june: this.tips.tips[5].tip,
            july: this.tips.tips[6].tip,
            august: this.tips.tips[7].tip,
            september: this.tips.tips[8].tip,
            october: this.tips.tips[9].tip,
            november: this.tips.tips[10].tip,
            december: this.tips.tips[11].tip
          }
        },
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function() {
          self.makeToast("SUCCESS", "Tips updated successfully", "success");
          self.loading = false;
        })
        .catch(error => self.makeToast("ERROR", error, "danger"));
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    }
  }
};
</script>
